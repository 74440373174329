import $ from "jquery";

window.product_grid = {
    addToBasket(e) {
        const el = $(e);
        const slug = el.data("product-slug");
        const track = el.data("track");

        el.css("width", el.outerWidth()).html(
            '<i class="fa fa-spin fa-spinner" style="height: 11px"></i>',
        );

        basket.addProduct(slug, 1, () => {
            el.html(
                '<img src="https://luxplus.imgix.net/content/icons/check.svg" alt="" class="svg-filter-white" style="height: 9px;">',
            );

            setTimeout(() => {
                el.html(window.TEXT530);
            }, 5000);
        });

        if (track === 1) {
            fetch(`/tracking/google/quick-add-to-cart/${slug}`)
                .then((response) => response.json())
                .then((data) => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(data);
                });
        }
    },
};

$(".item-badge-premium").each(function () {
    $(this).css("height", $(".item-badge-premium").outerWidth());
});
