const favorites = {
    toggle: function (element, productId) {
        const selectedListId = element.getAttribute("value");
        const selectedProductListId = element.getAttribute("id");
        var thisFavoriteList =
            ".favorite-list[data-id='" + selectedProductListId + "']";

        $(thisFavoriteList).toggleClass("favorite");

        if ($(thisFavoriteList).hasClass("favorite")) {
            $(thisFavoriteList).find("input").prop("checked", true);
        } else {
            $(thisFavoriteList).find("input").prop("checked", false);
        }

        this.toggleBadgeHeart(productId);

        fetch("/favorite/toggle/" + selectedListId, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-CSRF-Token": window.CSRF_TOKEN,
            },
            method: "POST",
            body: JSON.stringify({
                productId: productId,
            }),
        });
    },

    openFavorites: function (e) {
        var $this = $(e);
        var listsContainer = $this.siblings(".favorite-lists-container");

        listsContainer.slideToggle({
            start: function () {
                $(this).css({
                    display: "flex",
                });
            },
        });

        $this.toggleClass("open");
    },

    initFavoritesSelector: function (id) {
        var swiperId = id.id;
        var initialized = false;

        if (initialized == false) {
            new swiperScroll(swiperId, {
                direction: "vertical",
                freeMode: true,
                slidesPerView: "auto",
                watchSlidesProgress: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                    draggable: true,
                },
                mousewheel: {
                    forceToAxis: true,
                },
            });

            initialized = true;
        }
    },

    toggleBadgeHeart: function (productId) {
        var favoriteBadgeWrapper = $(
            ".lux-badge-wrapper.favorites[data-id='" + productId + "']",
        );
        var favoriteBadge = $(favoriteBadgeWrapper).find(".lux-badge");
        var listsContainer = $(favoriteBadgeWrapper).siblings(
            ".favorite-lists-container",
        );

        if (listsContainer.find(".favorite-list.favorite").length) {
            $(favoriteBadgeWrapper).addClass("active");
            $(favoriteBadge).addClass("heart-full").removeClass("heart-empty");
        } else {
            $(favoriteBadgeWrapper).removeClass("active");
            $(favoriteBadge).removeClass("heart-full").addClass("heart-empty");
        }
    },
};

window.favorites = favorites;
