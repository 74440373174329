var footerLinks = document.querySelectorAll(".footer-links a");

footerLinks.forEach(function (link) {
    if (link.getAttribute("data-faq")) {
        var faqBox = link.getAttribute("data-faq");

        link.addEventListener("click", function () {
            localStorage.setItem("footer-click-faq", faqBox);
        });
    }
});
