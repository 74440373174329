import { validateEmail } from "../../helpers/utils";
let delayTimer;

const addToWaitlist = {
    gridToggle: function (element, productId) {
        const email = element
            .querySelector(".waitlist-email")
            .getAttribute("value");
        let checker = 0;
        const toastAdded = document.querySelector(".toast-added-to-waitlist");
        const toastRemoved = document.querySelector(
            ".toast-removed-from-waitlist",
        );

        if (validateEmail(email)) {
            if (element.classList.contains("active")) {
                $.post("/remove_from_waitlist", {
                    _token: window.CSRF_TOKEN,
                    email,
                    product_id: productId,
                    newsletter: checker,
                })
                    .done(() => {
                        element.classList.remove("active");
                        luxPopup.toastShow(toastRemoved);
                    })
                    .fail(() => {});
            } else {
                $.post("/add_to_waitlist", {
                    _token: window.CSRF_TOKEN,
                    email,
                    product_id: productId,
                    newsletter: checker,
                })
                    .done(() => {
                        element.classList.add("active");
                        luxPopup.toastShow(toastAdded);
                    })
                    .fail(() => {});
            }
        }
    },
    productToggle: function (element, productId) {
        const emailWrapper = element
            .closest(".lux-input-group")
            .querySelector(".waitlist-email");
        const email = element
            .closest(".lux-input-group")
            .querySelector(".waitlist-email")["value"];
        const textAdded = document.querySelector(".lux-signup .added-text");
        const textNotAdded = document.querySelector(
            ".lux-signup .not-added-text",
        );
        const alreadyAdded = element.querySelector(".already-added");
        const notAdded = element.querySelector(".not-added");
        const justAdded = element.querySelector(".just-added");
        const justRemoved = element.querySelector(".just-removed");
        const buttonTexts = element.getElementsByTagName("span");
        let checker = 0;
        const toastAdded = document.querySelector(".toast-added-to-waitlist");
        const toastRemoved = document.querySelector(
            ".toast-removed-from-waitlist",
        );

        function hideTexts() {
            for (let span of buttonTexts) {
                span.classList.add("d-none");
            }
        }

        if (validateEmail(email)) {
            clearTimeout(delayTimer);

            if (
                document.getElementById("newsletter-checkbox") &&
                document.getElementById("newsletter-checkbox").checked
            ) {
                checker = 1;
            }

            if (element.classList.contains("added")) {
                $.post("/remove_from_waitlist", {
                    _token: window.CSRF_TOKEN,
                    email,
                    product_id: productId,
                    newsletter: checker,
                })
                    .done(() => {
                        element.classList.remove("added");
                        hideTexts();
                        justRemoved.classList.remove("d-none");
                        textAdded.classList.add("d-none");
                        textNotAdded.classList.remove("d-none");
                        luxPopup.toastShow(toastRemoved);

                        delayTimer = setTimeout(function () {
                            hideTexts();
                            justRemoved.classList.add("d-none");
                            notAdded.classList.remove("d-none");
                        }, 4000);
                    })
                    .fail(() => {});
            } else {
                $.post("/add_to_waitlist", {
                    _token: window.CSRF_TOKEN,
                    email,
                    product_id: productId,
                    newsletter: checker,
                })
                    .done(() => {
                        element.classList.add("added");
                        hideTexts();
                        justAdded.classList.remove("d-none");
                        textAdded.classList.remove("d-none");
                        textNotAdded.classList.add("d-none");
                        luxPopup.toastShow(toastAdded);

                        delayTimer = setTimeout(function () {
                            hideTexts();
                            justRemoved.classList.add("d-none");
                            alreadyAdded.classList.remove("d-none");
                        }, 4000);
                    })
                    .fail(() => {});
            }
        } else {
            emailWrapper.classList.add("lux-animate", "lux-shake");
            emailWrapper.focus();
            setTimeout(() => {
                emailWrapper.classList.remove("lux-animate", "lux-shake");
            }, 1000);
        }

        element.blur();
    },
};

window.addToWaitlist = addToWaitlist;
