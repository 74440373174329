window.onload = function () {
    if (document.querySelector(".youtube-video-wrapper")) {
        const youtubeVideos = document.querySelectorAll(
            ".youtube-video-wrapper .video",
        );

        youtubeVideos.forEach(function (video) {
            const source = video.getAttribute("data-src");
            video.setAttribute("src", source);
        });
    }
};
